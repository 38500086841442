.gctClient-app .pointer-events-none {
  pointer-events: none;
}

.gctClient-app .absolute {
  position: absolute;
}

.gctClient-app .relative {
  position: relative;
}

.gctClient-app .inset-0 {
  inset: 0;
}

.gctClient-app .left-3 {
  left: 12px;
}

.gctClient-app .top-1\/2 {
  top: 50%;
}

.gctClient-app .top-2 {
  top: 8px;
}

.gctClient-app .z-50 {
  z-index: 50;
}

.gctClient-app .mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}

.gctClient-app .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.gctClient-app .my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.gctClient-app .my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.gctClient-app .-mt-0\.5 {
  margin-top: -2px;
}

.gctClient-app .mb-2 {
  margin-bottom: 8px;
}

.gctClient-app .mb-4 {
  margin-bottom: 16px;
}

.gctClient-app .ml-4 {
  margin-left: 16px;
}

.gctClient-app .ml-5 {
  margin-left: 20px;
}

.gctClient-app .ml-auto {
  margin-left: auto;
}

.gctClient-app .mr-2 {
  margin-right: 8px;
}

.gctClient-app .mr-3 {
  margin-right: 12px;
}

.gctClient-app .mt-1 {
  margin-top: 4px;
}

.gctClient-app .mt-2 {
  margin-top: 8px;
}

.gctClient-app .mt-4 {
  margin-top: 16px;
}

.gctClient-app .block {
  display: block;
}

.gctClient-app .inline-block {
  display: inline-block;
}

.gctClient-app .flex {
  display: flex;
}

.gctClient-app .inline-flex {
  display: inline-flex;
}

.gctClient-app .grid {
  display: grid;
}

.gctClient-app .hidden {
  display: none;
}

.gctClient-app .size-0 {
  width: 0;
  height: 0;
}

.gctClient-app .size-3 {
  width: 12px;
  height: 12px;
}

.gctClient-app .size-6 {
  width: 24px;
  height: 24px;
}

.gctClient-app .size-\[22px\] {
  width: 22px;
  height: 22px;
}

.gctClient-app .size-full {
  width: 100%;
  height: 100%;
}

.gctClient-app .h-12 {
  height: 48px;
}

.gctClient-app .h-4 {
  height: 16px;
}

.gctClient-app .h-5 {
  height: 20px;
}

.gctClient-app .h-6 {
  height: 24px;
}

.gctClient-app .max-h-60 {
  max-height: 240px;
}

.gctClient-app .min-h-96 {
  min-height: 384px;
}

.gctClient-app .w-11 {
  width: 44px;
}

.gctClient-app .w-2 {
  width: 8px;
}

.gctClient-app .w-24 {
  width: 96px;
}

.gctClient-app .w-3 {
  width: 12px;
}

.gctClient-app .w-32 {
  width: 128px;
}

.gctClient-app .w-full {
  width: 100%;
}

.gctClient-app .min-w-32 {
  min-width: 128px;
}

.gctClient-app .max-w-screen-lg {
  max-width: 1024px;
}

.gctClient-app .max-w-sm {
  max-width: 384px;
}

.gctClient-app .flex-1 {
  flex: 1;
}

.gctClient-app .shrink-0 {
  flex-shrink: 0;
}

.gctClient-app .grow {
  flex-grow: 1;
}

.gctClient-app .grow-0 {
  flex-grow: 0;
}

.gctClient-app .-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.gctClient-app .translate-x-0\.5 {
  --tw-translate-x: 2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.gctClient-app .-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.gctClient-app .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.gctClient-app .animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.gctClient-app .cursor-pointer {
  cursor: pointer;
}

.gctClient-app .select-none {
  user-select: none;
}

.gctClient-app .grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gctClient-app .grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.gctClient-app .flex-row {
  flex-direction: row;
}

.gctClient-app .flex-col {
  flex-direction: column;
}

.gctClient-app .flex-wrap {
  flex-wrap: wrap;
}

.gctClient-app .items-start {
  align-items: flex-start;
}

.gctClient-app .items-end {
  align-items: flex-end;
}

.gctClient-app .items-center {
  align-items: center;
}

.gctClient-app .justify-start {
  justify-content: flex-start;
}

.gctClient-app .justify-end {
  justify-content: flex-end;
}

.gctClient-app .justify-center {
  justify-content: center;
}

.gctClient-app .justify-between {
  justify-content: space-between;
}

.gctClient-app .gap-1 {
  gap: 4px;
}

.gctClient-app .gap-2 {
  gap: 8px;
}

.gctClient-app .gap-3 {
  gap: 12px;
}

.gctClient-app .gap-4 {
  gap: 16px;
}

.gctClient-app :is(.space-y-2 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

.gctClient-app .overflow-hidden {
  overflow: hidden;
}

.gctClient-app .overflow-y-auto {
  overflow-y: auto;
}

.gctClient-app .overflow-x-clip {
  overflow-x: clip;
}

.gctClient-app .truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.gctClient-app .whitespace-nowrap {
  white-space: nowrap;
}

.gctClient-app .whitespace-pre-wrap {
  white-space: pre-wrap;
}

.gctClient-app .rounded {
  border-radius: 4px;
}

.gctClient-app .rounded-full {
  border-radius: 9999px;
}

.gctClient-app .rounded-md {
  border-radius: 6px;
}

.gctClient-app .rounded-xl {
  border-radius: 12px;
}

.gctClient-app .border {
  border-width: 1px;
}

.gctClient-app .border-2 {
  border-width: 2px;
}

.gctClient-app .border-x-4 {
  border-left-width: 4px;
  border-right-width: 4px;
}

.gctClient-app .border-r-2 {
  border-right-width: 2px;
}

.gctClient-app .border-t-4 {
  border-top-width: 4px;
}

.gctClient-app .border-\[\#8c8c8c\] {
  --tw-border-opacity: 1;
  border-color: rgb(140 140 140 / var(--tw-border-opacity));
}

.gctClient-app .border-\[\#d40511\] {
  --tw-border-opacity: 1;
  border-color: rgb(212 5 17 / var(--tw-border-opacity));
}

.gctClient-app .border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.gctClient-app .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.gctClient-app .border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.gctClient-app .border-transparent {
  border-color: #0000;
}

.gctClient-app .border-t-gray-800 {
  --tw-border-opacity: 1;
  border-top-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.gctClient-app .bg-\[\#e5e5e5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.gctClient-app .bg-\[\#ebebeb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(235 235 235 / var(--tw-bg-opacity));
}

.gctClient-app .bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.gctClient-app .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.gctClient-app .p-1 {
  padding: 4px;
}

.gctClient-app .p-2 {
  padding: 8px;
}

.gctClient-app .p-3 {
  padding: 12px;
}

.gctClient-app .p-4 {
  padding: 16px;
}

.gctClient-app .p-5 {
  padding: 20px;
}

.gctClient-app .px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.gctClient-app .px-\[10\.5px\] {
  padding-left: 10.5px;
  padding-right: 10.5px;
}

.gctClient-app .px-\[24px\] {
  padding-left: 24px;
  padding-right: 24px;
}

.gctClient-app .py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.gctClient-app .py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.gctClient-app .py-\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px;
}

.gctClient-app .pr-4 {
  padding-right: 16px;
}

.gctClient-app .pr-\[15px\] {
  padding-right: 15px;
}

.gctClient-app .pt-4 {
  padding-top: 16px;
}

.gctClient-app .text-left {
  text-align: left;
}

.gctClient-app .text-center {
  text-align: center;
}

.gctClient-app .text-right {
  text-align: right;
}

.gctClient-app .text-\[10px\] {
  font-size: 10px;
}

.gctClient-app .text-base {
  font-size: 16px;
  line-height: 24px;
}

.gctClient-app .text-sm {
  font-size: 14px;
  line-height: 20px;
}

.gctClient-app .text-xl {
  font-size: 20px;
  line-height: 28px;
}

.gctClient-app .text-xs {
  font-size: 12px;
  line-height: 16px;
}

.gctClient-app .font-bold {
  font-weight: 700;
}

.gctClient-app .font-medium {
  font-weight: 500;
}

.gctClient-app .capitalize {
  text-transform: capitalize;
}

.gctClient-app .leading-loose {
  line-height: 2;
}

.gctClient-app .leading-none {
  line-height: 1;
}

.gctClient-app .leading-relaxed {
  line-height: 1.625;
}

.gctClient-app .text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.gctClient-app .text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.gctClient-app .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.gctClient-app .opacity-20 {
  opacity: .2;
}

.gctClient-app .shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.gctClient-app .outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.gctClient-app .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.gctClient-app .transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.gctClient-app .duration-100 {
  transition-duration: .1s;
}

.gctClient-app .will-change-transform {
  will-change: transform;
}

[data-radix-menu-content] {
  width: var(--radix-dropdown-menu-trigger-width);
  max-height: var(--radix-dropdown-menu-content-available-height);
}

@media (width >= 768px) and (width <= 1023.98px) {
  .gctClient-app {
    padding-left: 4.2rem;
    padding-right: 4.2rem;
  }
}

@media (width <= 767px) {
  .gctClient-app {
    padding-left: 2.1rem;
    padding-right: 2.1rem;
  }
}

.gctClient-app :is(.group:last-child .group-last\:hidden) {
  display: none;
}

.gctClient-app :is(.group:focus .group-focus\:border-2) {
  border-width: 2px;
}

.gctClient-app :is(.group:focus .group-focus\:border-black) {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.gctClient-app :is(.group[data-state="open"] .group-\[\[data-state\=open\]\]\:rounded-b-none) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.gctClient-app :is(.group[data-state="open"] .group-\[\[data-state\=open\]\]\:border-2) {
  border-width: 2px;
}

.gctClient-app :is(.group[data-state="open"] .group-\[\[data-state\=open\]\]\:border-b-0) {
  border-bottom-width: 0;
}

.gctClient-app :is(.group[data-state="open"] .group-\[\[data-state\=open\]\]\:border-black) {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.gctClient-app .data-\[disabled\]\:pointer-events-none[data-disabled] {
  pointer-events: none;
}

.gctClient-app .data-\[state\=checked\]\:translate-x-\[21px\][data-state="checked"] {
  --tw-translate-x: 21px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.gctClient-app .data-\[disabled\]\:cursor-not-allowed[data-disabled] {
  cursor: not-allowed;
}

.gctClient-app .data-\[state\=open\]\:rounded-t-none[data-state="open"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.gctClient-app .data-\[state\=open\]\:border-t[data-state="open"] {
  border-top-width: 1px;
}

.gctClient-app .data-\[state\=open\]\:border-t-gray-400[data-state="open"] {
  --tw-border-opacity: 1;
  border-top-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.gctClient-app .data-\[highlighted\]\:bg-\[\#e5e5e5\][data-highlighted] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.gctClient-app .data-\[highlighted\]\:outline-none[data-highlighted] {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

@media (width >= 480px) {
  .gctClient-app .xs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 666px) {
  .gctClient-app .sm\:flex-row {
    flex-direction: row;
  }

  .gctClient-app .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .gctClient-app .sm\:justify-center {
    justify-content: center;
  }

  .gctClient-app .sm\:px-\[32px\] {
    padding-left: 32px;
    padding-right: 32px;
  }

  .gctClient-app .sm\:py-\[22px\] {
    padding-top: 22px;
    padding-bottom: 22px;
  }
}

@media (width >= 768px) {
  .gctClient-app .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .gctClient-app .md\:my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .gctClient-app .md\:mt-0 {
    margin-top: 0;
  }

  .gctClient-app .md\:w-auto {
    width: auto;
  }

  .gctClient-app .md\:flex-row {
    flex-direction: row;
  }

  .gctClient-app .md\:items-center {
    align-items: center;
  }

  .gctClient-app .md\:justify-end {
    justify-content: flex-end;
  }

  .gctClient-app .md\:justify-between {
    justify-content: space-between;
  }

  .gctClient-app .md\:gap-6 {
    gap: 24px;
  }

  .gctClient-app .md\:p-4 {
    padding: 16px;
  }
}

@media (width >= 1024px) {
  .gctClient-app .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
/*# sourceMappingURL=index.12c7db44.css.map */
