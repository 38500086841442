@tailwind utilities;

/* from https://www.radix-ui.com/primitives/docs/components/dropdown-menu#constrain-the-contentsub-content-size */
[data-radix-menu-content] {
    width: var(--radix-dropdown-menu-trigger-width);
    max-height: var(--radix-dropdown-menu-content-available-height);
}

/* Apply padding from dhl.com as of 29.10.2024 */

@media (min-width: 768px) and (max-width: 1023.98px) {
    .gctClient-app {
        padding-left: 4.2rem;
        padding-right: 4.2rem;
    }
}

@media (max-width: 767px) {
    .gctClient-app {
        padding-left: 2.1rem;
        padding-right: 2.1rem;
    }
}